<div class="wrapper-lg">
    <div class="padder-lg">
        <div ng-controller="SigninController">

            <div class="panel panel-default m-t-xl w-xxl" style="margin: 0 auto">
                <div class="panel-heading">
                    <span class="text-bold">Sign in</span>
                </div>

                <div class="panel-body">

                    <div class="m-b">
                        Please log in with your TCTMD account e-mail address and password. If you do not recall your password, please request it at
                        the <a target="_blank" href="https://www.tctmd.com/forgot-password">Forgot Password</a> link below. If you do not have an account.
                    </div>


                    <form name="form" class="form-validation">
                        <div class="text-danger wrapper text-center" ng-show="authError">
                            {{authError}}
                        </div>
                        <div class="list-group list-group-sm">
                            <div class="list-group-item">
                                <input type="text" placeholder="TCTMD E-mail" class="form-control no-border"
                                       ng-model="user.username" required>
                            </div>
                            <div class="list-group-item">
                                <input type="password" placeholder="Password" class="form-control no-border"
                                       ng-model="user.password" required>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-lg btn-primary font-bold btn-block" ng-click="submit()" ng-disabled='form.$invalid'>
                            Sign in
                        </button>
                        <div class="text-center m-t m-b"><a target="_blank" href="https://www.tctmd.com/forgot-password">Forgot password?</a></div>

                    </form>

                </div>

            </div>

            <div class="m-b-lg">

            </div>
        </div>
    </div>
</div>