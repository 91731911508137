<div ng-class="{ 'print_container_basic' : agenda_layout == 'basic' || agenda_layout == 'basic-photos' }">
    <h3 class="m-sm">{{conference.title}}</h3>
    <div class="m-sm">
        <div class="h4 font-bold text-primary2 text-primary2-print">
            {{event_agenda.title}}
            <span ng-show="event_agenda.description" class="font-normal"><br><em>{{event_agenda.description}}</em></span>
            <span ng-show="event_agenda.internal_description" class="font-normal"><br><em>{{event_agenda.internal_description}}</em></span>
        </div>

        <span ng-hide="event_agenda.params.on_demand">
            <span ng-show="is_virtual || is_hybrid">
                <span class="text-success text-success-print"><i class="fa fa-play"></i></span> Broadcast:
            </span>

            {{formatMomentDate(event_agenda.datetime_start, 'dddd, MMMM Do YYYY')}} <br>
            {{formatMomentDate(event_agenda.datetime_start,'h:mm A')}} - {{formatMomentDate(event_agenda.datetime_end,'h:mm A', conference.venue.timezone)}}<br>

            <span ng-hide="is_virtual">Room: <span ng-bind-html="formatVenuePath(event_agenda.venue.breadcrumb) | unsafe"></span><br></span>
        </span>

        <span class="font-bold">{{event_agenda.session_type}}</span>

        <div ng-show="agenda_layout == 'basic'">
            <div ng-repeat="event_agenda_faculty in event_agenda.moderators track by $index">

                <div style="margin-top: 20px" ng-show="event_agenda_faculty.users.length > 1">{{event_agenda_faculty.role.name_plural}}: </div>
                <div style="margin-top: 20px" ng-show="event_agenda_faculty.users.length == 1">{{event_agenda_faculty.role.name}}: </div>
                {{check_role=event_agenda_faculty.role.name;""}}

                <div ng-if="showPhoto(check_role)" class="row is-flex-print">
                    <div class="m-l-sm text-center"
                         ng-repeat="user in event_agenda_faculty.users track by $index | orderBy: 'last_name'"
                    >
                        <span ng-include="'faculty_list'"></span>
                    </div>
                </div>

                <span ng-if="!showPhoto(check_role)"
                      ng-repeat="user in event_agenda_faculty.users track by $index | orderBy: 'last_name'">
                    <span ng-include="'faculty_list'"></span>
                </span>
            </div>
        </div>

        <div ng-show="agenda_layout == 'full'">

            <script type="text/ng-template" id="faculty_list_container_event_agenda_faculty">
                <span ng-show="event_agenda_faculty.users.length > 1">{{event_agenda_faculty.role.name_plural}}: </span>
                <span ng-show="event_agenda_faculty.users.length == 1">{{event_agenda_faculty.role.name}}: </span>

                <span ng-repeat="user in event_agenda_faculty_users track by $index | orderBy: 'last_name'">
                    <span ng-include="'faculty_list'"></span>
                </span>
            </script>

            <div ng-repeat="event_agenda_faculty in event_agenda.moderators track by $index">

                <div ng-repeat="(meeting_role,event_agenda_faculty_users) in event_agenda_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="event_agenda_faculty_users[0].meeting_role == 'faculty' && event_agenda_faculty_users[0].alternate == '0'">
                        <span ng-include="'faculty_list_container_event_agenda_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,event_agenda_faculty_users) in event_agenda_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="event_agenda_faculty_users[0].meeting_role == 'faculty' && event_agenda_faculty_users[0].alternate == '1'">
                        <span>Alternate </span>
                        <span ng-include="'faculty_list_container_event_agenda_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,event_agenda_faculty_users) in event_agenda_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="event_agenda_faculty_users[0].meeting_role == 'pending-faculty' && event_agenda_faculty_users[0].alternate == '0'">
                        <span>Pending </span>
                        <span ng-include="'faculty_list_container_event_agenda_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,event_agenda_faculty_users) in event_agenda_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="event_agenda_faculty_users[0].meeting_role == 'pending-faculty' && event_agenda_faculty_users[0].alternate == '1'">
                        <span>Alternate Pending </span>
                        <span ng-include="'faculty_list_container_event_agenda_faculty'"></span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="m-sm m-t" ng-repeat="section in event_agenda.children track by $index">
        <div class="font-bold text-info text-info-print">
            {{section.title}}
            <span ng-show="section.description" class="font-normal"><br><em>{{section.description}}</em></span>
            <span ng-show="section.internal_description" class="font-normal"><br><em>{{section.internal_description}}</em></span>
        </div>

        <span ng-hide="section.params.on_demand">

            <div class="font-bold text-info text-info-print">
                <span ng-show="is_virtual || is_hybrid">
                    <span ng-show="isLiveEvent(section)" class="label m-r-xs bg-white text-danger text-danger-print">LIVE</span>
                    <span class="text-success text-success-print"><i class="fa fa-play"></i></span> <span class="font-bold text-info text-info-print">Broadcast:</span>
                </span>

                {{formatMomentDate(section.datetime_start,'h:mm A')}} - {{formatMomentDate(section.datetime_end,'h:mm A', conference.venue.timezone)}}
            </div>

            <span ng-show="(is_virtual || is_hybrid) && section.recording_datetime_start">
                <span class="text-danger text-danger-print"><i class="glyphicon glyphicon-record"></i></span>
                Record/Call Time: {{formatMomentDate(section.recording_datetime_start, 'ddd, MMM D, h:mm A')}} - {{formatMomentDate(section.recording_datetime_end, 'h:mm A', conference.venue.timezone)}}
                <span ng-show="section.videoconference_link.url"><br><span class="text-info text-info-print"><i class="fa fa-video-camera"></i></span> Videoconference Link: {{section.videoconference_link.url}}</span>
            </span>

        </span>

        <div ng-show="agenda_layout == 'basic'">
            <div ng-repeat="section_faculty in section.moderators track by $index">
                <div style="margin-top: 20px" ng-show="section_faculty.users.length > 1">{{section_faculty.role.name_plural}}: </div>
                <div style="margin-top: 20px" ng-show="section_faculty.users.length == 1">{{section_faculty.role.name}}: </div>
                {{check_role=section_faculty.role.name;""}}

                <span ng-if="!showPhoto(check_role)" ng-repeat="user in section_faculty.users track by $index | orderBy: 'last_name'">
                    <span ng-include="'faculty_list'"></span>
                </span>

                <div ng-if="showPhoto(check_role)" class="row is-flex-print">
                    <div class="m-l-sm text-center"
                         ng-repeat="user in section_faculty.users track by $index | orderBy: 'last_name'">
                        <span ng-include="'faculty_list'"></span>
                    </div>
                </div>
            </div>
        </div>

        <div ng-show="agenda_layout == 'full'">
            <script type="text/ng-template" id="faculty_list_container_section_faculty">
                <span ng-show="section_faculty.users.length > 1">{{section_faculty.role.name_plural}}: </span>
                <span ng-show="section_faculty.users.length == 1">{{section_faculty.role.name}}: </span>

                <span ng-repeat="user in section_faculty_users track by $index | orderBy: 'last_name'">
                        <span ng-include="'faculty_list'"></span>
                    </span>
            </script>

            <div ng-repeat="section_faculty in section.moderators track by $index">
                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'faculty' && section_faculty_users[0].alternate == '0'">
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'faculty' && section_faculty_users[0].alternate == '1'">
                        <span>Alternate </span>
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'pending-faculty' && section_faculty_users[0].alternate == '0'">
                        <span>Pending </span>
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'pending-faculty' && section_faculty_users[0].alternate == '1'">
                        <span>Alternate Pending </span>
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'challenging-case-presenter' && section_faculty_users[0].alternate == '0'">
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'abstract-presenter' && section_faculty_users[0].alternate == '0'">
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>

                <div ng-repeat="(meeting_role,section_faculty_users) in section_faculty.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="section_faculty_users[0].meeting_role == 'industry-faculty' && section_faculty_users[0].alternate == '0'">
                        <span ng-include="'faculty_list_container_section_faculty'"></span>
                    </div>
                </div>
            </div>
        </div>


        <div ng-class="{ 'm-l-md' : section.children}" ng-repeat="presentation in section.children track by $index">
            <div ng-include="'event_content_presentation'"></div>
            <div ng-class="{ 'm-l-md' : presentation.children}" ng-repeat="presentation in presentation.children track by $index">
                <div ng-include="'event_content_presentation'"></div>
                <div ng-class="{ 'm-l-md' : presentation.children}" ng-repeat="presentation in presentation.children track by $index">
                    <div ng-include="'event_content_presentation'"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<script type="text/ng-template" id="faculty_list">
    <span ng-show="agenda_layout == 'full'">
        <crf-status-assignment status-type="{{user.assignment_status}}"></crf-status-assignment>
    </span>

    <span ng-show="agenda_layout == 'full'"
          ng-class="{'{{'bg-highlight-' + user.conflict.type + ' bg-highlight-' + user.conflict.type + '-print wrapper-xxs'}}' : user.conflict.type}"
    >
        {{user.full_name}}<span ng-show="user.degree">, {{user.degree}}</span><span ng-show="!$last">;</span>
    </span>

    <span ng-show="agenda_layout == 'basic' && !showPhoto(check_role)">
        {{user.full_name}}<span ng-show="user.degree">, {{user.degree}}</span><span ng-show="!$last">;</span>
    </span>

    <div style="border: 0px solid #ccc; width: 100px; height: 200px" ng-show="agenda_layout == 'basic' && showPhoto(check_role)">

        <div class="thumb-md">
            <div class="image-cropper">
                <!--<div class="rounded rounded_lg" style="-webkit-print-color-adjust:exact !important;" ng-style="{'background-image' : 'url(/faculty/' + user.id} + '/profile_photo)'}"></div>-->
                <img ng-src="/faculty/{{user.id}}/profile_photo" style="width: 100px;" />
            </div>
        </div>

        <div>
            {{user.full_name}}<span ng-show="user.degree">, {{user.degree}}</span>
        </div>

    </div>

</script>

<script type="text/ng-template" id="presentation_faculty_list">
    <span ng-show="agenda_layout == 'full'">
        <crf-status-assignment status-type="{{presentation_faculty.assignment_status}}"></crf-status-assignment>
    </span>

    <span ng-show="agenda_layout == 'full'"
          ng-class="{'{{'bg-highlight-' + presentation_faculty.conflict.type + ' bg-highlight-' + presentation_faculty.conflict.type + '-print wrapper-xxs'}}' : presentation_faculty.conflict.type}">
        {{presentation_faculty.full_name}}
        <span ng-show="presentation_faculty.degree">, {{presentation_faculty.degree}}</span>  - <em>
                <span ng-show="presentation_faculty.meeting_role == 'pending-faculty'">Pending</span>
                <span ng-show="presentation_faculty.alternate">Alternate </span>{{presentation_faculty_roles.role.name}}</em>
    </span>
    <span ng-show="agenda_layout == 'basic' && !showPhoto(check_role)">
        {{presentation_faculty.full_name}}<span ng-show="presentation_faculty.degree">, {{presentation_faculty.degree}}</span> <span ng-show="presentation.moderators.length > 1">- <em>{{presentation_faculty_roles.role.name}}</em></span>
    </span>

    <span ng-show="agenda_layout == 'basic' && showPhoto(check_role)" >
        {{presentation_faculty.full_name}}<span ng-show="presentation_faculty.degree">, {{presentation_faculty.degree}}</span> <span ng-show="presentation.moderators.length > 1">- <em>{{presentation_faculty_roles.role.name}}</em></span>
    </span>
</script>

<script type="text/ng-template" id="event_content_presentation">
    <div class="m-t-sm">

        <span ng-hide="section.params.on_demand">

            <span ng-show="is_virtual || is_hybrid">
                <span ng-show="isLiveEvent(presentation)" class="label m-r-xs bg-white text-danger text-danger-print">LIVE</span>
                <span class="text-success text-success-print"><i class="fa fa-play"></i></span> Broadcast:
            </span>

            {{formatMomentDate(presentation.datetime_start,'h:mm A')}} - {{formatMomentDate(presentation.datetime_end,'h:mm A')}}<br>

            <div ng-show="(is_virtual || is_hybrid) && presentation.recording_datetime_start">
                <span class="text-danger text-danger-print"><i class="glyphicon glyphicon-record"></i></span>
                Record/Call Time: {{formatMomentDate(presentation.recording_datetime_start, 'ddd, MMM D, h:mm A')}} - {{formatMomentDate(presentation.recording_datetime_end, 'h:mm A')}}
                <div ng-if="presentation.videoconference_link.url">
                    <span class="text-info text-info-print"><i class="fa fa-video-camera"></i></span> Videoconference Link: {{presentation.videoconference_link.url}}
                </div>
                <div ng-if="presentation.recording_duration"><em>Presentation Length: {{presentation.duration}} minutes</em></div>
            </div>

        </span>

        <div><strong>{{presentation.title}}</strong></div>
        <span ng-show="presentation.description" class="font-normal"><em>{{presentation.description}}</em></span>
        <span ng-show="presentation.internal_description" class="font-normal"><br><em>{{presentation.internal_description}}</em></span>
        <br>

        <div ng-show="agenda_layout == 'basic'">
            <div ng-repeat="presentation_faculty in presentation.moderators track by $index">
                <!--<span ng-show="presentation.moderators.length > 1">
                    <span ng-show="presentation_faculty.users.length > 1">{{presentation_faculty.role.name_plural}}: </span>
                    <span ng-show="presentation_faculty.users.length == 1">{{presentation_faculty.role.name}}: </span>
                </span>--->
                <span ng-show="presentation_faculty.users.length > 1">{{presentation_faculty.role.name_plural}}: </span>
                <span ng-show="presentation_faculty.users.length == 1">{{presentation_faculty.role.name}}: </span>

                <span ng-repeat="user in presentation_faculty.users track by $index | orderBy: 'last_name'">
                    <span ng-include="'faculty_list'"></span>
                </span>
            </div>
        </div>

        <div ng-show="agenda_layout == 'full'">
            <div ng-repeat="presentation_faculty_roles in presentation.moderators track by $index">

                <div ng-repeat="(meeting_role, presentation_faculty_users) in presentation_faculty_roles.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="presentation_faculty_users[0].meeting_role == 'faculty' && presentation_faculty_users[0].alternate == '0'">
                        <div ng-repeat="presentation_faculty in presentation_faculty_users track by $index | toArray: true | orderBy: 'last_name'">
                            <div ng-include="'presentation_faculty_list'"></div>
                        </div>
                    </div>
                </div>

                <div ng-repeat="(meeting_role, presentation_faculty_users) in presentation_faculty_roles.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="presentation_faculty_users[0].meeting_role == 'abstract-presenter' && presentation_faculty_users[0].alternate == '0'">
                        <div ng-repeat="presentation_faculty in presentation_faculty_users track by $index | toArray: true | orderBy: 'last_name'">
                            <div ng-include="'presentation_faculty_list'"></div>
                        </div>
                    </div>
                </div>

                <div ng-repeat="(meeting_role, presentation_faculty_users) in presentation_faculty_roles.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="presentation_faculty_users[0].meeting_role == 'industry-faculty' && presentation_faculty_users[0].alternate == '0'">
                        <div ng-repeat="presentation_faculty in presentation_faculty_users track by $index | toArray: true | orderBy: 'last_name'">
                            <div ng-include="'presentation_faculty_list'"></div>
                        </div>
                    </div>
                </div>

                <div ng-repeat="(meeting_role, presentation_faculty_users) in presentation_faculty_roles.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="presentation_faculty_users[0].meeting_role == 'challenging-case-presenter' && presentation_faculty_users[0].alternate == '0'">
                        <div ng-repeat="presentation_faculty in presentation_faculty_users track by $index | toArray: true | orderBy: 'last_name'">
                            <div ng-include="'presentation_faculty_list'"></div>
                        </div>
                    </div>
                </div>

                <div ng-repeat="(meeting_role, presentation_faculty_users) in presentation_faculty_roles.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="presentation_faculty_users[0].meeting_role == 'pending-faculty' && presentation_faculty_users[0].alternate == '0'">
                        <div ng-repeat="presentation_faculty in presentation_faculty_users track by $index | toArray: true | orderBy: 'last_name'">
                            <div ng-include="'presentation_faculty_list'"></div>
                        </div>
                    </div>
                </div>

                <div ng-repeat="(meeting_role, presentation_faculty_users) in presentation_faculty_roles.users | groupBy: '[meeting_role, alternate]'">
                    <div ng-show="presentation_faculty_users[0].alternate == '1'">
                        <div ng-repeat="presentation_faculty in presentation_faculty_users track by $index | toArray: true | orderBy: 'last_name'">
                            <div ng-include="'presentation_faculty_list'"></div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</script>

