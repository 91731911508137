<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p>
            Please review your assignments below and accept or decline each. Please select <span class="font-bold">Expand All</span> to view all.
            You may leave a comment on your assignment/s or an overall comment related to your participation in the meeting as a whole.
            Please click <span class="font-bold">Save <span ng-show="task.status == '0'">and Continue</span></span> when you are finished.
        </p>

        <div class="text-danger font-bold" ng-show="is_virtual || is_hybrid">
        <ul>
            <li>Assignment times listed are based <em>on local time</em> for the meeting.</li>
            <li>Note: All Live Case Pre-record Assignments are Eastern Time. You will be sent a separate calendar invitation for these roles.</li>
        </ul>
        </div>

        <div class="m-t-sm m-b-xl">
            <span class="pull-right">
                <button type="button" ng-click="changeAssignmentCollapsed('collapse')" class="btn btn-default btn-xs"><i class="fa fa-chevron-up"></i> Collapse All</button>
                <button type="button" ng-click="changeAssignmentCollapsed('expand')" class="btn btn-default btn-xs"><i class="fa fa-chevron-down"></i> Expand All</button>
                <button type="button" ng-click="printAssignments()" class="btn btn-default btn-xs"><i class="fa fa-print"></i> Print</button>
                <!--<button type="button" ng-click="downloadCalendarAssignments()" class="btn btn-default btn-xs"><i class="fa fa-calendar"></i> Add all to Calendar</button>-->
            </span>
        </div>

        <div ng-repeat="(key,value) in faculty_assignments | groupBy: 'event.group_by_day' track by $index">
            <div class="hbox">
                <div class="col w">
                    <a ng-click="assignment_collapse_state[$index] = !assignment_collapse_state[$index];"><span class="h4 font-bold" ng-init="assignment_collapse_state[$index] = false">{{key | uppercase}}</span></a>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-primary font-bold btn-xs m-l-sm" ng-click="acceptDayAll(key)">Accept All On {{key}}</button>
                </div>
                <div class="col text-right">
                    <span>
                        <a ng-click="assignment_collapse_state[$index] = !assignment_collapse_state[$index];">
                            <i ng-class="{'fa-angle-right' : assignment_collapse_state[$index], 'fa-angle-down' : !assignment_collapse_state[$index]}" class="fa fa-fw fa-2x"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div class="line line-double line-lg b-b b-t b-primary2"></div>
            <div uib-collapse="assignment_collapse_state[$index]">
                <div ng-repeat="assignment in value track by $index">
                    <div class="row" ng-init="assignment_reply[assignment.id].status = assignment.status;">
                        <div class="col-sm-3">

                            <span ng-show="is_virtual">
                                <span class="text-danger">
                                    <i class="glyphicon glyphicon-record"></i>
                                </span>
                                Record/Call Time: {{formatMomentDate(assignment.event.recording_datetime_start,'h:mm A')}} - {{formatMomentDate(assignment.event.recording_datetime_end,'h:mm A',conference.venue.timezone)}}
                            </span>
                            <span ng-show="!is_virtual">
                                {{formatMomentDate(assignment.event.datetime_start,'h:mm A')}} - {{formatMomentDate(assignment.event.datetime_end,'h:mm A')}}
                            </span>
                            <span ng-show="(is_virtual || is_hybrid) && isLiveEvent(assignment.event)" class="label m-r-xs bg-danger">LIVE</span>
                            <!--<button class="btn btn-default btn-xs m-t-md" ng-click="downloadCalendarAssignment(assignment.id)">
                                <i class="fa fa-fw fa-calendar"></i> Add to calendar
                            </button>-->
                        </div>
                        <div class="col-sm-9">
                            <div class="h5 font-thin font-bold">
                                <i ng-show="assignment_reply[assignment.id].status == 'Accepted'" uib-tooltip="Accepted" class="fa fa-fw fa-check-circle text-success"></i>
                                <i ng-show="assignment_reply[assignment.id].status == 'Declined'" uib-tooltip="Declined" class="fa fa-fw fa-times-circle text-danger"></i>
                                <i ng-show="assignment_reply[assignment.id].status == 'Unknown'" uib-tooltip="Unknown" class="fa fa-fw fa-question-circle text-warning"></i>

                                <span>{{assignment.event.title}}</span>

                                <span class="pull-right">
                                    <!--<button class="btn btn-info btn-xs" ng-show="assignment_reply[assignment.id].status == 'Accepted'" ng-click="addToCalendar(assignment)">Add To Calendar</button>-->
                                    <button class="btn btn-default btn-xs" ng-click="printAgenda(assignment)">Print Session</button>
                                    <button class="btn btn-default btn-xs" ng-click="viewSession(assignment)">View Session</button>
                                </span>
                            </div>
                            <div class="m-t-sm" ng-show="assignment.event.breadcrumb"><em class="font-bold">a part of: </em>{{assignment.event.breadcrumb}}</div>

                            <div class="m-t-sm" ng-show="is_virtual || is_hybrid">
                                <em><span class="font-bold">Broadcasting:</span> {{formatMomentDate(assignment.event.datetime_start, 'MMM D, YYYY')}},  <!--Sept. 10, 2020--->
                                    {{formatMomentDate(assignment.event.datetime_start, 'h:mm A')}}
                                    - {{formatMomentDate(assignment.event.datetime_end, 'h:mm A',  conference.venue.timezone)}}
                                </em> |
                                <em ng-show="(assignment.event.depth == '1')"><span class="font-bold">Duration:</span> {{assignment.event.duration}} minutes</em>
                                <em ng-show="(assignment.event.depth > 1)"><span class="font-bold">Presentation Length:</span> {{assignment.event.duration}} minutes</em>
                            </div>
                            <div class="m-t-sm" ng-show="assignment.event.description">{{assignment.event.description}}</div>
                            <div class="m-t-sm" ng-show="assignment.event.internal_description">{{assignment.event.internal_description}}</div>
                            <div class="m-t-sm" ng-show="assignment.event.videoconference_link.url">
                                <span class="font-bold">Videoconference Link:</span> <a href="{{assignment.event.videoconference_link.url}}" target="_blank">{{assignment.event.videoconference_link.url}}</a>
                            </div>

                            <div class="m-t-sm">{{assignment.moderator_role}} <span ng-hide="is_virtual">| {{assignment.event.venue}}</span></div>

                            <div class="line line-dashed b-b b-primary2"></div>

                            <div ng-if="assignment.event.vendor_ids.length === 0">

                                <div class="m-b-sm text-right">
                                    <div class="hbox">
                                        <div class="col w-md">
                                            <button ng-click="selectAccept(assignment.id)"
                                                    ng-class="{
                                            'btn-success' : assignment_reply[assignment.id].status == 'Accepted',
                                            'btn-disabled btn-default' : assignment_reply[assignment.id].status == 'Declined',
                                            'btn-default' : assignment_reply[assignment.id].status == 'Unknown'
                                            }"
                                                    class="btn btn-sm"
                                            >ACCEPT<span ng-show="assignment_reply[assignment.id].status == 'Accepted'">ED</span></button>

                                            <button ng-click="selectDecline(assignment.id)"
                                                    ng-class="{
                                            'btn-danger' : assignment_reply[assignment.id].status == 'Declined',
                                            'btn-disabled btn-default' : assignment_reply[assignment.id].status == 'Accepted',
                                            'btn-default' : assignment_reply[assignment.id].status == 'Unknown'
                                            }"
                                                    class="btn btn-sm"
                                            >DECLINE<span ng-show="assignment_reply[assignment.id].status == 'Declined'">D</span></button>
                                        </div>
                                        <div class="col w" ng-show="assignment_reply[assignment.id].status == 'Declined'">
                                            <div class="m-l-sm">
                                                <ui-select ng-model="assignment_reply[assignment.id].decline_reason" theme="bootstrap" ng-init="assignment_reply[assignment.id].decline_reason = assignment.decline_reason">
                                                    <ui-select-match placeholder="Select reason for decline ...">{{$select.selected}}</ui-select-match>
                                                    <ui-select-choices repeat="option in decline_reasons">
                                                        <div ng-bind-html="option"></div>
                                                    </ui-select-choices>
                                                </ui-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-right m-t-sm"
                                         ng-show="assignment_reply[assignment.id].decline_reason == 'Other'"
                                         ng-init="assignment_reply[assignment.id].decline_reason_other = assignment.decline_other">
                                        <input placeholder="Enter decline reason"
                                               type="text"
                                               style="display: inline;"
                                               class="w-sm m-l-sm form-control input-sm"
                                               ng-model="assignment_reply[assignment.id].decline_reason_other"
                                        >
                                    </div>
                                </div>

                                <div ng-init="assignment_reply[assignment.id].comments = assignment.faculty_comment" class="m-b-sm" ng-show="assignment_reply[assignment.id].status == 'Accepted' || assignment_reply[assignment.id].status == 'Declined'">
                                    <em>Please enter comments (e.g. title change request, suggestion, question, etc)</em>
                                    <textarea ng-model="assignment_reply[assignment.id].comments" style="width: 100%; height: 25px; width: 100%"></textarea>
                                </div>
                            </div>

                            <div ng-if="assignment.event.vendor_ids.length > 0" class="m-t-sm">
                                <em>This assignment has been accepted on your behalf. Please contact the administrator of the session to make changes.</em>
                            </div>

                            <div class="line  line-double line-lg b-b b-t b-primary2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="m-t">
            <div class="line  line-double line-lg b-b b-t b-primary2"></div>

            <ul>
                <li ng-show="general_note.note_title == 'Assignment Comment'" ng-repeat="general_note in assignments_general_comments_notes">
                    {{general_note.note}}<br>
                    <em class="small">Entered on {{formatMomentDate(general_note.updated_at, 'MM/DD/YYYY')}}</em>
                </li>
            </ul>

            <em>Please enter general comments</em>
            <textarea ng-model="assignments_general_comments" style="width: 100%; height: 50px;"></textarea>

        </div>

        <div class="line line-dashed b-b b-primary2"></div>

        <div class="m-t-xl text-danger" ng-show="assignments_errors.length">
            <span class="font-bold">Please complete the following:</span>
            <div class="line line-dashed b-b b-danger"></div>

            <div ng-repeat="assignment_error in assignments_errors">
                <span class="font-bold">{{assignment_error.text}}</span>
            </div>

            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save <span ng-show="task.status == '0'">and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>


    </div>
</div>